import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock, StudyDesignOverview, StudyDesignArms } from '../index';

const MEL_ADJUVANT_2 = () => (
    <ComponentWrapper>
        <StudyDesignOverview
            title="Study Overview"
            description="Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 976 patients<sup>2</sup>"
            data={[
                [
                    {
                        title: 'Select Eligibility Criteria',
                        description: `
                            <ul>
                                <li>Age ≥12 years</li>
                                <li>Completely resected stage IIB or stage IIC melanoma</li>
                                <li>No previous treatment for melanoma beyond complete surgical resection</li>
                            </ul>
                            <p>Randomization was stratified by AJCC 8th edition T stage (>2.0–4.0&nbsp;mm with ulceration vs >4.0&nbsp;mm without ulceration vs >4.0&nbsp;mm with ulceration).</p>
                        `,
                        type: 'box-gradient',
                        colspan: 2
                    }
                ],
                [
                    {
                        type: 'arrow-gray',
                        colspan: 2
                    }
                ],
                [
                    {
                        title: 'Randomized 1:1 (N=976)',
                        colspan: 2
                    }
                ],
            ]}
        />
    
        <StudyDesignArms title="Study Arms<sup>2,7</sup>" arms={[
            {
                title: "KEYTRUDA",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `
                                <p>Adults: KEYTRUDA 200&nbsp;mg IV Q3W</p>
                                <p>Pediatric (≥12 years old): KEYTRUDA 2&nbsp;mg/kg up to a maximum of 200&nbsp;mg IV Q3W</p>
                                <p>Treatment continued for up to 1 year (n=487)</p>
                            `,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Recurrence >6 months',
                            description: `(after completion of 1 year of pembrolizumab)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Post Recurrence (Unblinded Treatment)',
                            description: `
                                <p>Adults: KEYTRUDA 200&nbsp;mg IV Q3W</p>
                                <p>Pediatric (≥12 years old): KEYTRUDA 2&nbsp;mg/kg up to a maximum of 200&nbsp;mg IV Q3W</p>
                                <p>Treatment continued until disease recurrence, unacceptable toxicity, or up to 2 years</p>
                            `,
                            type: 'box-green',
                        }
                    ]
                ]
            },
            {
                title: "Placebo",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Placebo IV Q3W for up to 1 year (n=489)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Recurrence',
                            description: `Crossover to KEYTRUDA`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Post Recurrence (Unblinded Treatment)',
                            description: `
                                <p>Adults: KEYTRUDA 200&nbsp;mg IV Q3W</p>
                                <p>Pediatric (≥12 years old): KEYTRUDA 2&nbsp;mg/kg up to a maximum of 200&nbsp;mg IV Q3W</p>
                                <p>Treatment continued until disease recurrence, unacceptable toxicity, or up to 2 years</p>
                            `,
                            type: 'box-green',
                        }
                    ]
                ]
            }
        ]} />
    
        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Major efficacy outcome measure (primary endpoint):</p>
                <ul>
                    <li>The major efficacy outcome measure was investigator-assessed RFS defined as the time between the date of randomization and the date of first recurrence [local, in-transit, or regional lymph nodes, or distant recurrence] or death, whichever occurred first).</li>
                    <li>New primary melanomas were excluded from the definition of RFS.
                    Patients underwent imaging every 6 months for 1 year from randomization, every 6 months from years 2 to 4, and then once in year 5 from randomization or until recurrence, whichever came first.
                    </li>
                </ul>
                <p>Additional efficacy outcome measure (secondary endpoint):</p>
                <ul>
                    <li>The additional efficacy outcome measure was DMFS defined as the time from randomization to the first diagnosis of distant metastasis per RECIST v1.1. Distant metastasis refers to cancer that has spread from the original (primary) tumor and beyond local tissues and lymph nodes to distant organs or distant lymph nodes.<sup>7</sup></li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>Baseline Characteristics of Patients (N=976)</p>
                <p>All patients randomized at baseline:</p>
                <ul>
                    <li>Median age: 61 years (range: 16–87), 39% age 65 or older</li>
                    <li>Male: 60%</li>
                    <li>White: 98%</li>
                    <li>ECOG PS of 0: 93%; ECOG PS of 1: 7%</li>
                    <li>Stage IIB: 64%</li>
                    <li>Stage IIC: 35%</li>
                </ul>
            `} 
        />
    </ComponentWrapper>
);

export default MEL_ADJUVANT_2;
