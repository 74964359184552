import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock, StudyDesignOverview, StudyDesignArms } from '../index';

const MEL_ADJUVANT = () => (
    <ComponentWrapper>
        <StudyDesignOverview
            title="Study Overview<sup>10,15</sup>"
            description="Phase 3, multicenter, randomized, double-blind, placebo-controlled trial conducted in collaboration with the European Organisation for Research and Treatment of Cancer (EORTC) to compare treatment with KEYTRUDA vs placebo following complete surgical resection of high risk, stage III melanoma, as well as an anti&#8288;–&#8288;PD&#8288;-1 rechallenge crossover design."
            data={[
                [
                    {
                        title: 'Select Eligibility Criteria<sup>10,15</sup>',
                        description: `
                            <ul>
                                <li>Age ≥18 years</li>
                                <li>Completely resected stage IIIA (>1-mm lymph node metastasis), IIIB or IIIC melanoma (AJCC, 7th ed.)</li>
                                <li>PD&#8288;-&#8288;L1 expression evaluation</li>
                                <li>Lymph node dissection within 13 weeks prior to starting treatment</li>
                                <li>Randomization within 13 weeks of surgery</li>
                                <li>Radiotherapy within 13 weeks post-surgery, if indicated, prior to starting treatment</li>
                            </ul>

                            <p>Randomization was stratified by American Joint Committee on Cancer (AJCC) 7th ed stage (IIIA vs IIIB vs IIIC 1-3 positive lymph nodes vs IIIC ≥4 positive lymph nodes) and geographic region (North America, European countries, Australia, and other countries as designated).</p>
                        `,
                        type: 'box-gradient',
                        colspan: 2
                    }
                ],
                [
                    {
                        type: 'arrow-gray',
                        colspan: 2
                    }
                ],
                [
                    {
                        title: 'Randomized 1:1 (N=1019)',
                        colspan: 2
                    }
                ],
            ]}
        />
    
        <StudyDesignArms title="Study Arms<sup>15</sup>" arms={[
            {
                title: "KEYTRUDA",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 200&nbsp;mg IV infusion Q3W for up to 1 year (n=514) until disease recurrence or unacceptable toxicity`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Recurrence >6 months',
                            description: `(after completion of 1 year of pembrolizumab)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Post Recurrence (Unblinded Treatment)',
                            description: `
                                <p>KEYTRUDA 200&nbsp;mg IV Q3W until disease recurrence, or unacceptable toxicity, or up to 2 years</p>
                            `,
                            type: 'box-green',
                        }
                    ]
                ]
            },
            {
                title: "Placebo",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Placebo IV Q3W for up to 1 year (n=505)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Recurrence',
                            description: `Crossover to KEYTRUDA`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Post Recurrence (Unblinded Treatment)',
                            description: `
                                <p>KEYTRUDA 200&nbsp;mg IV Q3W until disease recurrence, or unacceptable toxicity, or up to 2 years</p>
                            `,
                            type: 'box-green',
                        }
                    ]
                ]
            }
        ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Major efficacy outcome measure (primary endpoint):</p>
                <ul>
                    <li>Investigator assessed RFS in the whole population and in the population with PD&#8288;-&#8288;L1-positive tumors, where RFS was defined as the time between the date of randomization and the date of first recurrence (local, regional, or distant metastasis) or death, whichever occurs first.</li>
                    <li>New primary melanomas were excluded from the definition of RFS.</li>
                </ul>
                <p>Additional efficacy outcome measure (secondary endpoint):</p>
                <ul>
                    <li>The additional efficacy outcome measure was DMFS defined as the time from randomization to the first diagnosis of distant metastasis or date of death (whatever the cause), whichever occurs first.<sup>13</sup></li>
                </ul>
            `} 
        />
        
        <StudyCopyBlock 
            title="Study Design Details"
            description={`
                <ul>
                    <li>Patients underwent imaging every 12 weeks after the first dose of KEYTRUDA for the first 2 years, then every 6 months from Year 3 to 5, and then annually.</li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>Baseline Characteristics of Patients (N=1019)</p>
                <p>All patients randomized at baseline:</p>
                <ul> 
                    <li>Median age: 54 years (range: 19–88), 25% age 65 or older</li>
                    <li>Male: 62%</li>
                    <li>ECOG PS of 0: 94%; ECOG PS of 1: 6%</li>
                    <li>Stage IIIA: 16%</li>
                    <li>Stage IIIB: 46%</li>
                    <li>Stage IIIC (1-3 positive lymph nodes): 18%</li>
                    <li>Stage IIIC (≥4 positive lymph nodes): 20%</li>
                    <li><i>BRAF</i> V600 mutation positive: 50%</li>
                    <li><i>BRAF</i> wild type: 44%</li>
                    <li>PD&#8288;-&#8288;L1 positive with TPS ≥1% according to an IUO assay: 84%</li>
                </ul>
            `}
            definitions={`
                IV = intravenous; PD&#8288;-&#8288;L1 = programmed death ligand 1; ECOG PS = Eastern Cooperative Oncology Group performance status; TPS = tumor proportion score; IUO = investigational use only.
            `} 
        />
    </ComponentWrapper>
);

export default MEL_ADJUVANT;
